<template>
  <b-modal
    ref="game-modal"
    hide-footer
    title="รายละเอียดเกม"
    @show="resetModal"
    @hidden="resetModal"
  >
    <form
      id="game-form"
      ref="form"
      @submit.stop.prevent="handleSubmit"
    >
      <div v-if="data.img" class="text-center">
        <img :src="data.img" style="width: 150px;">
      </div>

      <b-form-group
        label="Img"
        label-class="text-primary"
      >
        <b-form-input
          v-model="input.img"
          type="text"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="Category"
        label-class="text-primary"
        v-slot="{ ariaDescribedby }"
      >
        <b-form-radio-group
          v-model="input.cats"
          :options="catOptions"
          :aria-describedby="ariaDescribedby"
        ></b-form-radio-group>
      </b-form-group>

      <b-form-group
        label="Creator"
        label-class="text-primary"
        v-slot="{ ariaDescribedby }"
      >
        <b-form-radio-group
          v-model="input.creator"
          :options="creOptions"
          :aria-describedby="ariaDescribedby"
        ></b-form-radio-group>
      </b-form-group>

      <b-form-group
        label="Name"
        label-class="text-primary"
      >
        <b-form-input
          v-model="input.name"
          type="text"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="*** productId"
        label-class="text-primary"
      >
        <b-form-input
          v-model="input.productId"
          type="text"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="category"
        label-class="text-primary"
      >
        <b-form-input
          v-model="input.category"
          type="text"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="*** Code"
        label-class="text-primary"
      >
        <b-form-input
          v-model="input.code"
          type="text"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="Type"
        label-class="text-primary"
      >
        <b-form-input
          v-model="input.type"
          type="text"
          number
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="Rank"
        label-class="text-primary"
      >
        <b-form-input
          v-model="input.rank"
          type="text"
          number
        ></b-form-input>
      </b-form-group>

      <div class="d-flex justify-content-end mt-3 pt-3">
        <b-button variant="link" size="sm" class="text-secondary mr-3" @click="hideModal">ยกเลิก</b-button>
        <b-button type="submit" variant="primary" size="sm" :disabled="isProcess">{{isProcess ? 'กำลังบันทึก...' : 'บันทึก'}}</b-button>
      </div>
    </form>
  </b-modal>
</template>
<script>
import GameService from '@/services/gameService'
import Swal from 'sweetalert2'

export default {
  name: 'GameModal',
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: {
        _id: null,
        name: null,
        cats: {
          _id: null,
          name: null
        },
        creator: {
          _id: null,
          name: null
        },
        code: null,
        category: null,
        img: null,
        isActive: null,
        productId: null,
        providerCode: null,
        rank: null,
        type: null
      }
    },
    categories: {
      type: Array,
      default: () => {
        return []
      }
    },
    creators: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      needReload: false,
      isProcess: false,
      input: {
        _id: null,
        name: null,
        cats: {
          _id: null,
          name: null
        },
        creator: {
          _id: null,
          name: null
        },
        code: null,
        category: null,
        img: null,
        isActive: null,
        productId: null,
        providerCode: null,
        rank: null,
        type: null
      }
    }
  },
  computed: {
    catOptions() {
      return this.categories.map((t)=>{
        return {
          text: t.name,
          value: {
            _id: t._id,
            name: t.name
          }
        }
      })
    },
    creOptions() {
      return this.creators
      .filter((c)=>{
        return c.catId === this.input.cats._id
      })
      .map((c)=>{
        return {
          text: c.name,
          value: {
            _id: c._id,
            name: c.name
          }
        }
      })
    }
  },
  watch: {
    isShow() {
      if(this.isShow){
        this.showModal()
      }else{
        this.hideModal()
      }
    },
    data() {
      this.input = JSON.parse(JSON.stringify(this.data))
    }
  },
  methods: {
    resetModal() {
      this.needReload = false
    },
    showModal() {
      this.$refs['game-modal'].show()
    },
    hideModal() {
      this.$refs['game-modal'].hide()
    },
    handleSubmit() {
      if(!this.input.name || !this.input.img || !this.input.productId || !this.input.code)
        return

      if(this.data._id) {
        this.update()
      }else{
        this.save()
      }
    },
    save() {
      GameService.saveGame(this.input)
      .then((response)=>{
        if(response.success) {
          this.needReload = true
          this.hideModal()
        }else{
          throw new Error(response?.message)
        }
      })
      .catch((e)=>{
        Swal.fire({
          title: 'ผิดพลาด!',
          text: e?.message || 'บันทึกข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
    },
    update() {
      GameService.updateGame(this.data._id, this.input)
      .then((response)=>{
        if(response.success) {
          this.needReload = true
          this.hideModal()
          this.$notify({
            type: 'success',
            title: 'สำเร็จ!',
            text: 'บันทึกเสร็จเรียบร้อย',
          })
        }else{
          throw {
            massage: response
          }
        }
      })
      .catch((e)=>{
        Swal.fire({
          title: 'ผิดพลาด!',
          text: 'บันทึกข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      this.$emit('close', this.needReload)
    })
  }
}
</script>
