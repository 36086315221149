<template>
  <div class="container-fluid">
    <div class="page-header mb-1">
      <strong>รายชื่อเกม</strong>
    </div>
    <div class="card">
      <div class="card-header">
        <ul class="nav nav-tabs card-header-tabs">
          <li v-for="cat in categories" :key="cat._id" class="nav-item">
            <a class="nav-link" :class="[{'active':catId===cat._id}]" href="#" target="_self" @click="catId=cat._id">{{ cat.name }}</a>
          </li>
        </ul>
      </div>

      <div class="p-1">
        <button
          class="btn btn-sm m-1"
          v-for="item in creatorItems"
          :key="item._id"
          :class="[{'btn-info': item._id===creId}, {'btn-outline-info': item._id!=creId}]"
          @click="creId=item._id"
        >{{ item.name }}</button>
      </div>

      <div>

        <table class="table-games table table-hover mb-0">
          <thead>
            <tr>
              <th width="60">เลือก</th>
              <th width="80">Rank</th>
              <th width="80">Img</th>
              <th width="200">code</th>
              <th>Name</th>
              <th>Status</th>
              <th></th>
            </tr>
            <tr v-if="items.length">
              <th class="text-left py-1" colspan="5">
                <b-form-checkbox v-model="checkAll" class="m-0" :value="true" :unchecked-value="false" />
              </th>
              <th class="py-1">
                <span v-if="bulkGameIds.length">
                  <b-form-checkbox v-model="bulkActive" :disabled="!bulkGameIds.length" name="check-button" switch :class="[bulkActive ? 'text-success' : 'text-danger']">
                    {{ bulkActive ? 'เปิด' : 'ปิด' }}
                  </b-form-checkbox>
                </span>
              </th>
              <th class="text-right py-1">
                <b-button :disabled="!bulkGameIds.length" variant="success" size="sm" @click="saveBulk">บันทึก</b-button>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in items" :key="item._id">
              <td>
                <b-form-checkbox
                  v-model="bulkGameIds"
                  :value="item._id"
                />
              </td>
              <td>{{ item.rank }}</td>
              <td class="p-0">
                <a v-if="item.img" :href="item.img" target="_blank"><img :src="item.img"></a>
              </td>
              <td>{{ item.code }}</td>
              <td>{{ item.name }}</td>
              <td>
                <b-form-checkbox v-model="item.isActive" name="check-button" switch @change="toggleChange(item)" :class="[item.isActive ? 'text-success' : 'text-danger']">
                  {{ item.isActive ? 'เปิด' : 'ปิด' }}
                </b-form-checkbox>
              </td>
              <td class="text-right">
                <button :disabled="!item.isActive" class="btn btn-sm btn-outline-primary" @click="editGame(item)"><i class="far fa-edit"></i></button>
              </td>
            </tr>
            <tr v-if="!items.length">
              <td colspan="8" class="text-center">ไม่มีข้อมูล</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <GameModal :data="game" :categories="categories" :creators="creators" :is-show="isShowModal" @close="modalClosed" />
  </div>
</template>
<script>
import GameService from '@/services/gameService'
import Swal from 'sweetalert2'
import GameModal from './components/GameModal'

export default {
  components: {
    GameModal
  },
  data() {
    return {
      categories: [],
      creators: [],
      games: [],
      catId: null,
      creId: null,
      bulkGameIds: [],
      checkAll: false,
      bulkActive: false,
      isShowModal: false,
      game: {
        _id: null,
        name: null,
        cats: {
          _id: null,
          name: null
        },
        creator: {
          _id: null,
          name: null
        },
        code: null,
        category: null,
        img: null,
        isActive: null,
        productId: null,
        providerCode: null,
        rank: null,
        type: null
      }
    }
  },
  computed: {
    creatorItems() {
      const creators = this.creators.filter((cre)=>{
        return cre.catId === this.catId
      })

      if(creators.length) {
        this.creId = creators[0]._id
      }

      return creators
    },
    items() {
      const items = this.games.filter((game)=>{
        return game.cats._id === this.catId && game.creator._id === this.creId
      })

      return items
    }
  },
  watch: {
    checkAll(n, o) {
      this.bulkGameIds = []
      if(n){
        for(const item of this.items) {
          this.bulkGameIds.push(item._id)
        }
      }
    },
    catId() {
      this.bulkGameIds = []
      this.checkAll = false
      this.bulkActive = false
    }
  },
  methods: {
    loadCats() {
      GameService.getCreators().then((response)=>{
        if(response.success) {
          this.categories = response.data.categories
          this.creators = response.data.creators

          if(this.categories.length) {
            this.catId = this.categories[0]._id
          }
        }
      })
    },
    loadGames() {
      GameService.getGames().then((response)=>{
        if(response.success) {
          this.games = response.data
        }
      })
    },
    toggleChange(game) {
      this.updateStatus([game._id], game.isActive)
    },
    saveBulk() {
      this.updateStatus(this.bulkGameIds, this.bulkActive)
    },
    updateStatus(gameIds, isActive) {
      const updateData = {
        gameIds: gameIds,
        isActive: isActive
      }

      GameService.updateStatus(updateData)
      .then((response)=>{
        if(!response.success) {
          throw new Error(response.data)
        }else{
          this.bulkGameIds = []
          this.checkAll = false
          this.bulkActive = false
        }
      })
      .catch((e)=>{
        Swal.fire({
          title: 'ไม่สำเร็จ!',
          text: e.message,
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.loadGames()
      })
    },
    editGame(game) {
      this.game = game
      this.isShowModal = true
    },
    modalClosed(needReload) {
      this.isShowModal = false
      if(needReload) {
        this.loadGames()
      }
    }
  },
  mounted() {
    this.loadGames()
    this.loadCats()
  }
}
</script>
<style lang="scss" scoped>
.table-games {
  th {
    border-top: 0px;
    vertical-align: middle;
  }

  td {
    vertical-align: middle;

    img {
      height: 60px;
    }
  }
}
</style>
